import React, { useRef, useState, useEffect } from "react";

import * as CONST from "../../CONSTANTS";
import { Input } from "reactstrap";
//import StateSelect from "./stateSelect";
import BackButton from "./backButton";
import NextButton from "./nextButton";

export default function address({ fData, handleNextClick, handleBackClick }) {
  const QUERY = "What is your Address?";
  const NEXT_COMPONENT = CONST.CN_EMAIL;

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  let stateTemplate = {};
  stateTemplate[CONST.ADDRESS_STREET] = "";
  stateTemplate[CONST.ADDRESS_CITY] = "";
  stateTemplate[CONST.ADDRESS_STATE] = "";

  const [completeAddress, setCompleteAddress] = useState(
    fData[CONST.DATA][CONST.CN_ADDRESS]
      ? fData[CONST.DATA][CONST.CN_ADDRESS][CONST.DATA] || stateTemplate
      : stateTemplate
  );
  const [isInputValid, setIsInputValid] = useState(true);

  const canContinue = () => {
    return (
      completeAddress[CONST.ADDRESS_STREET] !== ""
      //&&
      // completeAddress[CONST.ADDRESS_CITY] !== "" &&
      // completeAddress[CONST.ADDRESS_STATE] !== ""
    );
  };

  const handleChange = evt => {
    const { name, value } = evt.target;
    let _completeAddress = { ...completeAddress };

    _completeAddress[name] = value;
    setIsInputValid(true);
    setCompleteAddress(_completeAddress);
  };

  const onNextClick = () => {
    if (canContinue()) {
      let componentState = {};
      componentState[CONST.DATA] = { ...completeAddress };

      let componentInfo = {};
      componentInfo[CONST.DATA] = componentState;
      componentInfo[CONST.NEXT_COMPONENT] = NEXT_COMPONENT;

      handleNextClick(componentInfo);
    } else {
      setIsInputValid(false);
    }
  };

  const onBackClick = () => {
    setIsInputValid(true);
    handleBackClick();
  };

  return (
    <div className="address row">
      <div className="col-12 query">
        <h2>{QUERY}</h2>
      </div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please fill in your address
          </div>
        ) : null}
        <div className="row">
          <div className="col-12">
            <Input
              innerRef={inputEl}
              type="text"
              placeholder="Street address"
              name={CONST.ADDRESS_STREET}
              value={completeAddress[CONST.ADDRESS_STREET]}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>
        {/* <div className="row gx-2">
          <div className="col-4">
            <Input
              type="text"
              placeholder="City"
              name={CONST.ADDRESS_CITY}
              value={completeAddress[CONST.ADDRESS_CITY]}
              onChange={evt => handleChange(evt)}
            />
          </div>
          <div className="col-8">
            <StateSelect
              completeAddress={completeAddress}
              setCompleteAddress={setCompleteAddress}
            />
          </div>
        </div> */}
      </div>

      <div className="col-12">
        <NextButton onNextClick={onNextClick} />
        <BackButton onBackClick={onBackClick} />
      </div>
    </div>
  );
}
