import React from "react";
import { BACK } from "../../CONSTANTS";

export default function backButton({ onBackClick }) {
  return (
    <div className="back-button">
      <button
        className="btn btn-primary-outline"
        onClick={() => {
          onBackClick();
        }}
      >
        &laquo; {BACK}
      </button>
    </div>
  );
}
