import React, { useEffect, useState } from "react";
import * as CONST from "../../CONSTANTS";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function progressCircle({ fData }) {
  library.add(faChevronDown);
  const [percentage, SetPercentage] = useState();

  useEffect(() => {
    SetPercentage(getCompletedPercentage());
  }, [fData]);

  const getCompletedPercentage = () => {
    let completedPercentage = 0;
    if (fData[CONST.COMPONENT_FLOW].length > 1) {
      let totalScreens = CONST.TOTAL_PROGRESS_SCREENS;
      let stepsCompleted = fData[CONST.COMPONENT_FLOW].length;
      let stepPercentage = 100 / totalScreens;
      completedPercentage = parseInt(stepPercentage * stepsCompleted);
    }

    return completedPercentage;
  };

  return (
    <div
      className="progress-circle"
      style={{ maxWidth: "180px", margin: "auto", marginBottom: "45px" }}
    >
      <CircularProgressbarWithChildren
        value={percentage}
        background="true"
        strokeWidth="6"
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#000",
          backgroundColor: "#FFF",
          trailColor: "#e9ecef",
          pathColor: "#2AD2C9",
        })}
      >
        <div style={{ fontSize: 45, marginTop: -5, fontWeight: "600" }}>
          {fData[CONST.COMPONENT_FLOW].length > 1 ? `${percentage}%` : `Start`}
        </div>
        <div style={{ fontSize: 16, marginTop: -5 }}>
          {
            fData[CONST.COMPONENT_FLOW].length > 1 ? (
              `COMPLETED`
            ) : (
              <FontAwesomeIcon icon="chevron-down" />
            )
            // <i className="fa fa-fw fa-chevron-down" data-tf-id="130"></i>
          }
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
